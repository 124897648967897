/* This is a hack to circumvent Image from requiring a set height */
/* May be fixed by upcoming "next/future/image" */
.imageContainer {
  width: 100%;
}
.imageContainer > span {
  position: unset !important;
}
.image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}
