.circle {
  @apply relative z-0 inline-block w-full;
}
.circle:before {
  @apply absolute inset-0 -z-10 aspect-square w-full -rotate-45 rounded-full bg-gradient-to-l from-grey-asphalt via-grey-light to-white p-2 content-[''] tablet:p-4;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
